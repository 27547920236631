<template>
  <div>
    <px-filtro-currency-range title="Preço"
                       v-model="value"
                       @change="onSelectFilter"/>
  </div>
</template>

<script>
import { NumberUtils } from 'px-components';
import FiltroCurrencyRange from './FiltroCurrencyRange.vue';

const FILTER_KEY = 'precoAVista';

export default {
  props: {
    filters: Object,
  },
  data() {
    return {
      value: {
        min: null,
        max: null,
      },
    };
  },
  methods: {
    onSelectFilter(value) {
      this.$emit('change', value);
    },
    setupInitialValue() {
      if (this.filters && this.filters[FILTER_KEY]) {
        this.value.min = NumberUtils.fixNumberInput(this.filters[FILTER_KEY].min);
        this.value.max = NumberUtils.fixNumberInput(this.filters[FILTER_KEY].max);
      }
    },
  },
  created() {
    this.setupInitialValue();
  },
  components: {
    pxFiltroCurrencyRange: FiltroCurrencyRange,
  },
};
</script>

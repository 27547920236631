<template>
  <px-banner-cadastro-email :color="color"
                            :is-link="false"
                            :x-large="xLarge"
                            :sub-title="subTitle"
                            :paddings="paddings"
                            text-btn="Chamar no WhatsApp"
                            :container-class-prop="containerClassProp"
                            :text="bannerCadastroTextWhats"
                            @click="onClickBannerCadastro"/>
</template>

<script>
const RD_WHATSAPP_BTN = 'rd-floating_button-l08303ns';

export default {
  props: {
    subTitle: {
      type: String,
      default: 'Chame nossa equipe no WhatsApp!',
    },
    color: {
      type: String,
      default: 'success',
    },
    paddings: {
      type: String,
      default: 'pt-10 pb-10',
    },
    bannerCadastroTextWhats: {
      type: String,
      default: 'Dúvida? Fale com nosso time! Será um prazer te ajudar!',
    },
    phoneNumber: {
      type: String,
      default: '5548992120020',
    },
    containerClassProp: {
      type: String,
    },
    xLarge: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClickBannerCadastro() {
      const btn = document.getElementById(RD_WHATSAPP_BTN);
      if (!btn) {
        window.open(`https://api.whatsapp.com/send?phone=${this.phoneNumber}&text=Ol%C3%A1!%20Quero%20saber%20mais%20sobre%20o%20Marketplace%20Proxpect!`, '_newtab');
        return;
      }
      btn.click();
    },
  },
};
</script>

<template>
  <div class="d-inline-block">
    <v-btn outlined
           large
           class="mx-1 rounded-tag-small normal-btn-text"
           @click="onClickMenu">
      <v-icon left>
        mdi-filter-outline
      </v-icon>
      Filtros
      <v-icon>
        mdi-chevron-down
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="menuOpened"
      fixed
      app
    >
      <v-row class="ml-3">
        <v-col cols="12" class="font-weight-bold body-2">
          <px-ofertas-filters :type="type"
                              :value="value"
                              :query="query"
                              :filters="filters"
                              :is-search="isSearch"
                              :is-favoritos="isFavoritos"
                              @change="change"
                              @remove="remove"/>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import OfertasFilters from './OfertasFilters.vue';

export default {
  props: {
    filters: Object,
    type: {
      type: String,
      required: true,
    },
    isSearch: {
      type: Boolean,
      required: true,
      default: false,
    },
    isFavoritos: {
      type: Boolean,
      required: true,
      default: false,
    },
    value: [Number, String],
    query: String,
  },
  data() {
    return {
      menuOpened: false,
    };
  },
  methods: {
    onClickMenu() {
      this.menuOpened = !this.menuOpened;
    },
    change(event) {
      this.$emit('change', event);
    },
    remove(event) {
      this.$emit('remove', event);
    },
  },
  components: {
    pxOfertasFilters: OfertasFilters,
  },
};
</script>

<template>
  <div>
    <div class="title">
      {{ title }}
    </div>
    <v-skeleton-loader
      :loading="loading"
      type="list-item@3"
    >
      <v-list dense>
      <v-list-item v-for="option in options" :key="option.id" class="list-item-filter pl-1">
        <v-list-item-content class="pl-3 pt-0 pb-0">
          <v-checkbox
            class="mt-0 text--primary" style="border-radius: 5px;"
            dense
            @change="onChange(option)"
            v-model="option.enabled"
            hide-details
          >
            <template v-slot:label>
              <span class="body-2">{{ option.description }}</span>
            </template>
          </v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    </v-skeleton-loader>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Filtro',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: Array,
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
    },
  },
};
</script>

<style scoped>
.list-item-filter {
  min-height: 25px;
}
</style>

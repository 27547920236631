<template>
  <div class="mx-4" v-if="!loading && fornecedores.length">
    <v-row>
      <v-col cols="12" class="font-weight-bold">
        Fornecedores encontrados
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="" md="4"
             class="d-flex align-start justify-center"
             v-for="fornecedor in fornecedores"
             :key="fornecedor.id">
        <px-card-fornecedor class="full-width"
                            :fornecedor="fornecedor"/>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-divider/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FornecedorService from '@/api/fornecedor-marketplace-service';
import PxCardFornecedor from '../home-page/PxCardFornecedor.vue';

const LIMIT = 3;

export default {
  props: {
    searchQueryProp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      fornecedores: [],
    };
  },
  methods: {
    searchFornecedores() {
      if (!this.searchQueryProp) {
        return;
      }
      this.loading = true;
      FornecedorService.searchFornecedores({
        q: `%${this.searchQueryProp.toLowerCase()}%`,
        limit: LIMIT,
      })
        .then(({ data }) => {
          this.fornecedores = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.searchFornecedores();
  },
  components: {
    PxCardFornecedor,
  },
};
</script>

import { setQueryParam } from '@/services/route-oferta-service';
import { filterToHash, hashToFilter } from '@/modules/ofertas/filters/filter-service';

export default {
  data() {
    return {
      queryFilterString: null,
      filters: {},
      filtersHash: {},
      initialFilters: {},
    };
  },
  methods: {
    setupQueryFilter() {
      if (this.queryFilter) {
        const filters = this.queryFilter.split('--');
        filters.map(hashToFilter)
          .forEach((filter) => {
            this.filters[filter.property] = filter;
          });
        this.buildQueryString();
      }
    },
    buildQueryString() {
      const completeFilter = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const property in this.filters) {
        const filterString = this.buildQueryByPropertyType(this.filters[property]);
        completeFilter.push(filterString);
      }
      this.queryFilterString = completeFilter.join(' and ');
    },
    buildQueryByPropertyType(property) {
      if (!property.value) {
        return this.buildCustomProperty(property);
      }

      switch (typeof property.value) {
        case 'boolean':
          return this.buildQueryBooleanProperty(property);
        default:
          return this.buildQueryArrayProperty(property);
      }
    },
    buildQueryArrayProperty(property) {
      return `${property.property} in (${property.value.join(', ')})`;
    },
    buildQueryBooleanProperty(property) {
      return `${property.property} is ${property.value}`;
    },
    buildQueryRangeProperty(property) {
      const filters = [];
      if (property.min) {
        filters.push(`${property.property} >= ${property.min}`);
      }
      if (property.max) {
        filters.push(`${property.property} <= ${property.max}`);
      }
      return filters.join(' and ');
    },
    buildCustomProperty(property) {
      if (property.min || property.max) {
        return this.buildQueryRangeProperty(property);
      }
      return '';
    },
    updateHash() {
      const completeFilter = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const property in this.filtersHash) {
        completeFilter.push(this.filtersHash[property]);
      }
      setQueryParam('q', completeFilter.join('--'));
    },
    onAddFilter(event) {
      // Vue.set(this.filters, event.property, event);
      this.filters[event.property] = event;
      this.filtersHash[event.property] = filterToHash(event);
      this.updateHash();
      this.buildQueryString();
    },
    onRemoveFilter(event) {
      delete this.filters[event.property];
      delete this.filtersHash[event.property];
      this.updateHash();
      this.buildQueryString();
    },
  },
};

<template>
  <div class="ml-8 align-text-left">
    <v-row class="display-2 font-weight-bold">
      <v-col cols="12" md="8">
        Ops!
      </v-col>
    </v-row>
    <v-row class="title">
      <v-col cols="12">
        Parece que não temos exatamente o que você procura :(
      </v-col>
    </v-row>
    <v-row class="title">
      <v-col cols="12">
        Mas não se preocupe, temos muitos outros produtos para você!
      </v-col>
    </v-row>

    <v-row dense no-gutters class="mt-12">
      <v-col cols="12">
        <px-home-page-block query="promocao.ativo is true and disponivel is true order by random"
                            title="Outras ofertas para você"
                            :qtd-ofertas="30"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HomePageBlock from '../home-page/HomePageBlock.vue';

export default {
  components: {
    pxHomePageBlock: HomePageBlock,
  },
};
</script>

<template>
  <div>
    <div class="title">
      {{ title }}
    </div>
    <v-list dense>
      <v-list-item class="list-item-filter pl-1">
        <v-list-item-content class="pl-3 pt-0 pb-0">
          <v-switch hide-details
                    dense
                    class="pb-2 mr-2"
                    v-model="localValue"
                    @change="onChange">
            <template v-slot:label>
              <span class="body-2">
                {{ optionText }}
              </span>
            </template>
          </v-switch>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Filtro',
    },
    optionText: {
      type: String,
      required: true,
    },
    value: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
    },
  },
};
</script>

<style scoped>
.list-item-filter {
  min-height: 25px;
}
</style>

<template>
  <div>
    <px-filtro-boolean title="Promoção"
                       option-text="Apenas promoção"
                       v-model="isSelected"
                       @change="onSelectFilter"/>
  </div>
</template>

<script>
import FiltroBoolean from './FiltroBoolean.vue';

const FILTER_KEY = 'promocao.ativo';

export default {
  props: {
    filters: Object,
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    onSelectFilter(value) {
      this.$emit('change', value);
    },
    setupInitialValue() {
      if (this.filters && this.filters[FILTER_KEY]) {
        this.isSelected = this.filters[FILTER_KEY].value;
      }
    },
  },
  created() {
    this.setupInitialValue();
  },
  components: {
    pxFiltroBoolean: FiltroBoolean,
  },
};
</script>

<template>
  <div>
    <px-filtro-selecao title="Fornecedor"
                       :options="options"
                       :loading="loading"
                       @change="onSelectFilter"/>
  </div>
</template>

<script>
import { remove, isEmpty } from 'lodash';
import OfertaService from '../oferta-service';
import FiltroSelecao from './FiltroSelecao.vue';

export default {
  props: {
    filters: Object,
    query: String,
    isSearch: {
      type: Boolean,
      required: true,
      default: false,
    },
    isFavoritos: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      options: [],
      selected: [],
    };
  },
  methods: {
    onSelectFilter(event) {
      if (event.enabled) {
        this.selected.push(event);
      } else {
        remove(this.selected, item => item.id === event.id);
      }
      this.$emit('change', this.selected);
    },
    isFornecedorEnabled(fornecedor) {
      if (!this.filters || !this.filters.fornecedor || !this.filters.fornecedor.value) {
        return false;
      }
      return this.filters.fornecedor.value.includes(fornecedor.id);
    },
    buildOptionItem(fornecedor) {
      return {
        id: fornecedor.id,
        description: fornecedor.pessoa.nome,
        enabled: this.isFornecedorEnabled(fornecedor),
      };
    },
    getFornecedores() {
      this.loading = true;
      let params = {
        query: this.query,
      };

      if (this.isSearch) {
        params = {
          q: this.query,
        };
      }

      if (this.isFavoritos) {
        params.favorito = true;
      }

      OfertaService.getFornecedoresFilter(params)
        .then(({ data }) => {
          this.options = data.map(this.buildOptionItem);
          const initialSelection = this.options.filter(item => item.enabled);
          if (!isEmpty(initialSelection)) {
            this.selected = initialSelection;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFornecedores();
  },
  components: {
    pxFiltroSelecao: FiltroSelecao,
  },
};
</script>

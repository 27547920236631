<template>
  <div>
    <div class="title">
      {{ title }}
    </div>
    <v-row class="mt-3">
      <v-col cols="6">
        <px-input-money
          dense
          class="rounded-tag-small"
          ref="value"
          :label="minLabel"
          v-model="localValue.min"/>
      </v-col>
      <v-col cols="6">
        <px-input-money
          dense
          class="rounded-tag-small"
          ref="value"
          :label="maxLabel"
          v-model="localValue.max"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn block
               color="primary"
               class="normal-btn-text rounded-tag-small elevation-0"
               @click="onChange">
          Aplicar
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Filtro',
    },
    minLabel: {
      type: String,
      default: 'Min.',
    },
    maxLabel: {
      type: String,
      default: 'Máx.',
    },
    value: Object,
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.value);
    },
  },
};
</script>

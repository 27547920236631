import { isEmpty } from 'lodash';

function encodeHash(hash) {
  return btoa(hash);
}

function decodeHash(hash) {
  return atob(hash);
}

function buildStringHashProperty(item) {
  return `${item.property}:${JSON.stringify(item.value)}`;
}

function buildRangeHashPropery(item) {
  const { min, max } = item;
  return `${item.property}:${JSON.stringify({ min, max })}`;
}

function buildStringObject(item) {
  if (typeof item.value === 'boolean') {
    return buildStringHashProperty(item);
  }
  if (item.min || item.max) {
    return buildRangeHashPropery(item);
  }
  if (isEmpty(item.value)) {
    return '';
  }
  return buildStringHashProperty(item);
}

function filterToHash(item) {
  const filterString = buildStringObject(item);
  if (!filterString) {
    return '';
  }
  return encodeHash(filterString);
}

function hashToFilter(hash) {
  const decodedFilter = decodeHash(hash);
  if (!decodedFilter) {
    return null;
  }
  const [key, value] = decodedFilter.split(/:(.*)/s);
  const parsedValue = JSON.parse(value);

  if (parsedValue.min || parsedValue.max) {
    return {
      property: key,
      ...parsedValue,
    };
  }

  return {
    property: key,
    value: parsedValue,
  };
}

export {
  filterToHash,
  hashToFilter,
  buildStringObject,
  encodeHash,
  decodeHash,
};

<template>
  <div>
    <px-ofertas-filters :type="type"
                        :value="value"
                        :query="query"
                        :filters="filters"
                        :is-search="isSearch"
                        :is-favoritos="isFavoritos"
                        @change="change"
                        @remove="remove"/>
  </div>
</template>

<script>
import OfertasFilters from './OfertasFilters.vue';

export default {
  props: {
    filters: Object,
    type: {
      type: String,
      required: true,
    },
    isSearch: {
      type: Boolean,
      required: true,
      default: false,
    },
    isFavoritos: {
      type: Boolean,
      required: true,
      default: false,
    },
    value: [Number, String],
    query: String,
  },
  methods: {
    change(event) {
      this.$emit('change', event);
    },
    remove(event) {
      this.$emit('remove', event);
    },
  },
  components: {
    pxOfertasFilters: OfertasFilters,
  },
};
</script>
